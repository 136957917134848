import axios from 'axios'
import { Message } from 'element-ui'
// import { getToken } from '@/utils/auth'
import { BASE_URL,CONSUL_API } from './url_config'

import { Loading } from 'element-ui'
// create an axios instance
const service = axios.create({
  // baseURL: BASE_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
})
// request interceptor
let loading
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (!config.noLogging) {
      loading = Loading.service({
        lock: false,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
    }
    // if (store.getters.token) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    //   config.headers['X-Token'] = getToken()
    // }
    return config
  }
  // error => {
  //   // do something with request error
  //   // console.debug(error) // for debug
  //   return Promise.reject(error)
  // }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (loading) {
      loading.close()
    }
    const { data, headers, config } = response
    const res = data

    if (config.url.includes(CONSUL_API)) {
      return {
        Key: data[0].Key,
        data: Buffer.from(data[0].Value, 'base64').toString('utf-8'),
      }
    }

    if (res.status && res.status === 'failed') {
      Message({
        message: res.data || 'Error',
        type: 'error',
        duration: 5 * 1000,
      })
    }
    return res
    // if the custom code is not 20000, it is judged as an error.
    // if (res.code !== 20000) {
    //   Message({
    //     message: res.message || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
  },
  error => {
    loading.close()
    console.error(error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 1 * 1000,
    })
    return Promise.reject(error)
  }
)

export default service
